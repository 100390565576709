import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {BannerComponent} from './views/banner/banner.component';
import {DownloadsComponent} from './views/downloads/downloads.component';
import {NewsComponent} from './views/news/news.component';
import {ContactComponent} from './views/contact/contact.component';
import {NewsDetailComponent} from './views/news/detail/news-detail.component';
import {DownloadsDetailComponent} from './views/downloads/detail/downloads-detail.component';
import {BannerDetailComponent} from './views/banner/detail/banner-detail.component';
import {ArticlesComponent} from './views/articles/news.component';
import {ArticlesDetailComponent} from './views/articles/detail/news-detail.component';
import {CasesComponent} from './views/cases/news.component';
import {CasesDetailComponent} from './views/cases/detail/news-detail.component';
import {OthersComponent} from "./views/others/news.component";
import {OthersDetailComponent} from "./views/others/detail/news-detail.component";


const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'user', component: UserComponent},
            {
                path: 'access-control', component: AccessControlComponent, children: [
                    {
                        path: '', component: AccessControlHomeComponent
                    },
                ]
            },
            {
                path: 'banner', component: BannerComponent
            },
            {
                path: 'banner-detail/:id', component: BannerDetailComponent
            },
            {
                path: 'banner-detail', component: BannerDetailComponent
            },
            {
                path: 'downloads', component: DownloadsComponent
            },
            {
                path: 'downloads-detail/:id', component: DownloadsDetailComponent
            },
            {
                path: 'downloads-detail', component: DownloadsDetailComponent
            },
            {
                path: 'news', component: NewsComponent
            },
            {
                path: 'news-detail/:id', component: NewsDetailComponent
            },
            {
                path: 'news-detail', component: NewsDetailComponent
            },
            {
                path: 'articles', component: ArticlesComponent
            },
            {
                path: 'articles-detail/:id', component: ArticlesDetailComponent
            },
            {
                path: 'content', component: OthersComponent
            },
            {
                path: 'content-detail/:id', component: OthersDetailComponent
            },
            {
                path: 'cases', component: CasesComponent
            },
            {
                path: 'cases-detail/:id', component: CasesDetailComponent
            },
            {
                path: 'contact', component: ContactComponent
            },
            {path: 'home', component: HomeComponent},
            {path: '', component: HomeComponent},
        ],
        canActivate: [AuthGuard]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
