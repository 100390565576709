<div class="login">
    <div class="header">
        <svg-icon src="assets/svg/title.svg"></svg-icon>
        <div class="desc">
            <p>Design Sprint</p>
        </div>
    </div>
    <div class="content">
        <div class="form">
            <img class="logo-login" src="assets/images/logologin.png" alt="Desafio Liquigás"  />
            <b>LOGIN</b>
            <div class="input-material w-80">
                <input id="fieldRE" class="form-control" type="text" required [(ngModel)]="authInput.email"  />
                <label for="fieldRE">
                    <span>E-mail</span>
                </label>
            </div>
            <div class="input-material w-80">
                <input id="senha" class="form-control" type="password" required [(ngModel)]="authInput.password"
                       (keyup.enter)="handlerSigIn()" />
                <label for="senha">
                    <span>Senha</span>
                </label>
            </div>
            <div class="row justify-content-end align-items-end w-80 mt-3 mb-3">
                <!--<a href="javascript:void('');" >Esqueci minha Senha</a>-->
            </div>
            <button class="btn btn-warning  w-80 mt-3" tabindex="0" (click)="handlerSigIn()">
                <span class="pl-2">Entrar</span>
            </button>
        </div>
    </div>
</div>

