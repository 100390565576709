<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Banner<!-- | <span>Gestão</span>--></h1>

            <!-- <a [routerLink]="'/banner-create'"  href="javascript:void('');" class="btn btn-secondary" >
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar
            </a> -->
            <a href="javascript:void('');" class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
            <a [routerLink]="'/banner-detail/new'" href="javascript:void('');"
               class="btn btn-sm btn-secondary btn-text-white">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="keyboard" class="form-control" type="text" [(ngModel)]="modelFilter.keyword"/>
                <label for="keyboard">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'search' | translate}}</span>
                </label>
            </div>

            <div class="input-material">
                <input id="period" class="form-control js-daterangepicker" type="text"/>
                <label for="period">Período</label>
            </div>

            <a class="btn btn-primary btn-lg text-white" (click)="modelFilter.page = 1; handlerSearch();">Buscar</a>
            <!--<a class="btn btn-info btn-lg text-white" href="javascript:void('excel')">Exportar
                Excel</a>-->
        </div>

        <div class="w-100 d-block py-5" *ngIf="modelBannerResult.banner.length === 0">
            <p class="text-center py-5">Nenhum item cadastrado</p>
        </div>

        <!--GRID-->
        <div class="table-responsive" *ngIf="modelBannerResult.banner.length > 0">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Ordem</th>
                    <th scope="col">Imagem</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Destaque</th>
                    <th scope="col">Perfil</th>
                    <th scope="col">Agendmanento</th>
                    <th scope="col">Data de Criação</th>
                    <th scope="col">QTD de Acessos</th>
                    <th scope="col" class="w-10 text-right"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="false">
                    <td colspan="5">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let item of this.modelBannerResult.banner |
                paginate: {itemsPerPage: modelBannerResult.pageSize,
                currentPage: modelBannerResult.currentPage,
                totalItems: modelBannerResult.totalCount }">
                    <td>{{item.position}}</td>
                    <td><img class="imgThumb" [src]="getImageUrl(item.pathImage)" alt=""></td>
                    <td>{{item.title}}</td>
                    <td>{{item.highlight ? "Sim" : "Não"}}</td>
                    <td>{{item.profile}}</td>
                    <td>{{item.scheduled ? "Sim" : "Não"}}</td>
                    <td>{{item.createdAt | date:"dd/MM/yyyy"}}</td>
                    <td>{{item.bannerAccess.length}}</td>
                    <td class="text-right">
                        <a [routerLink]="['/banner-detail/' + item.id]">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('remove')" (click)="handlerRemoveItem(item.id)">
                            <svg-icon src="assets/svg/close-rounded.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
