import {Component, OnInit} from "@angular/core";
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardService} from "../../../service/dashboard.service";
import {UserService} from "../../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
    CreateNewsGQL,
    CreateNewsInput,
    GetNewByIdGQL,
    GetNewByIdQuery,
    GetNewByIdQueryVariables,
    News,
    RemoveNewsGQL,
    UpdateNewsGQL, UpdateNewsInput,
} from "src/generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "src/environments/environment";
import {NewsService} from "src/app/service/news.service";

declare var $: any;
declare var moment: any;

@Component({
    selector: "app-news-detail",
    templateUrl: "./news-detail.component.html",
    styleUrls: ["./news-detail.component.scss"],
})
export class NewsDetailComponent extends BaseComponent implements OnInit {
    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    agendar = false;
    id: string = '';
    modelNew: News = new News();

    newQuery: QueryRef<GetNewByIdQuery, GetNewByIdQueryVariables>;

    constructor(
        public router: Router,
        public dashboardService: DashboardService,
        public userService: UserService,
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private getNewByIdGQL: GetNewByIdGQL,
        private updateNewsGQL: UpdateNewsGQL,
        private createNewsGQL: CreateNewsGQL,
        private removeNewsGQL: RemoveNewsGQL,
        private newsService: NewsService
    ) {
        super(router, translate);
    }

    // Editor = ClassicEditor;
    Editor = ClassicEditor;
    optionsNews = {
        toolbar: {
            items: [
                "heading",
                "|",
                "fontsize",
                "|",
                "alignment",
                "|",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "outdent",
                "indent",
                "|",
                "bulletedList",
                "numberedList",
                "todoList",
                "|",
                "bold",
                "italic",
                "strikethrough",
                "underline",
                "subscript",
                "superscript",
                "|",
                "undo",
                "redo",
                "|",
                "insertTable",
            ],
            shouldNotGroupWhenFull: false,
        },
    };

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((param) => {
            this.id = param.id;
            this.setDate('js-dateStart', (start: any) => {
                this.modelNew.start = start.utcOffset(0, true).format();
            });
            this.setDate('js-dateEnd', (start: any) => {
                    this.modelNew.end = start.utcOffset(0, true).format();
                }, moment().add('days', 30));
            if (this.id !== 'new') {
                this.getNewById(this.id);
            } else {
                this.modelNew.highlight = false;
            }
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.file = o.file[0];
    }

    getNewById(newId: string): void {
        this.newQuery = this.getNewByIdGQL.watch({input: newId});
        this.newQuery.valueChanges.subscribe({
            next: ({data}) => {
                this.modelNew = data.new as News;
                // this.croppedImage = this.getImageUrl(this.modelNew.pathImage);
                if (this.modelNew.scheduled) {
                    // console.log('aplicar data do modelo nos datepicker star end');
                    this.setDate('js-dateStart', (start, end, label) => {
                        this.modelNew.start = start.utcOffset(0, true).format();
                    }, this.modelNew.start.toString());
                    this.setDate('js-dateEnd', (start, end, label) => {
                        this.modelNew.end = start.utcOffset(0, true).format();
                    }, this.modelNew.end.toString());
                }
            },
        });
    }

    getImageUrl(image: string): string {
        return `${environment.base_url_image}news/image/${image}`;
    }

    handlerSaveNews(): void {
        const arrValidateFields = [
            {value: this.modelNew.title, text: 'Titulo <br>'},
            {value: this.modelNew.description, text: 'Descrição <br>'},
            {value: this.modelNew.text, text: 'Conteúdo <br>'}
        ];

        if (this.modelNew.scheduled) {
            if (super.isNullOrUndefined(this.modelNew.start)) {
                arrValidateFields.push({value: '', text: 'Data início agendamento <br>'});
            }
            if (super.isNullOrUndefined(this.modelNew.end)) {
                arrValidateFields.push({value: '', text: 'Data fim agendamento <br>'});
            }
        }
        const strError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(strError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${strError}`, 'warning');
            return;
        }

        if (this.modelNew.scheduled && this.isNullOrUndefined(this.modelNew.start)) {
            this.modelNew.start = moment().utcOffset(0, true).format();
        }
        if (this.modelNew.scheduled && this.isNullOrUndefined(this.modelNew.end)) {
            this.modelNew.end = moment().add('days', 30).utcOffset(0, true).format();
        }

        if (this.id !== 'new') {
            const a: UpdateNewsInput = {
                id: this.id,
                title: this.modelNew.title,
                highlight: this.modelNew.highlight,
                scheduled: this.modelNew.scheduled ? this.modelNew.scheduled : false,
                type: 'news',
                text: this.modelNew.text,
                start: this.modelNew.scheduled ? this.modelNew.start : null,
                end: this.modelNew.scheduled ? this.modelNew.end : null,
                description: this.modelNew.description,
            };
            this.actionUpdateData(a);
        } else {
            if (this.isNullOrUndefined(this.file)) {
                this.showMessage('Atenção', `Adicione uma imagem para a notícia`, 'warning');
                return;
            }
            const a: CreateNewsInput = {
                title: this.modelNew.title,
                highlight: this.modelNew.highlight,
                scheduled: this.modelNew.scheduled ? this.modelNew.scheduled : false,
                type: 'news',
                text: this.modelNew.text,
                start: this.modelNew.scheduled ? this.modelNew.start : null,
                end: this.modelNew.scheduled ? this.modelNew.end : null,
                description: this.modelNew.description
            };
            this.actionCreateData(a);
        }
    }

    actionCreateData(a: any) {
        this.createNewsGQL
            .mutate({
                input: a,
            })
            .subscribe({
                next: ({data}) => {
                    const newsId = data.createNews.id;
                    this.newsService.upload(this.file, newsId).subscribe({
                        next: () => {
                            setTimeout(() => {
                                this.showMessage('Sucesso', 'Notícia cadastrado com sucesso!', 'success');
                                this.router.navigate(['/news']);
                            }, 100);
                        },
                        error: (err) => {
                            const message = JSON.parse(err).message;
                            super.showMessage('Atenção', message, 'error');
                            this.toggleLoader(false);
                            this.removeNewsGQL.mutate({
                                id: newsId,
                            }).subscribe({
                                next: () => {
                                },
                            });
                        },
                    });
                },
                error: (err) => {
                    this.toggleLoader(false);
                },
            });
    }

    actionUpdateData(a: any) {
        this.updateNewsGQL
            .mutate({
                input: a,
            })
            .subscribe({
                    next: ({data}) => {
                        if (this.file) {
                            const newsId = data.updateNews.id;
                            this.newsService.upload(this.file, newsId).subscribe({
                                next: () => {
                                    this.message();
                                    setTimeout(() => {
                                        this.router.navigate(['/news']);
                                    }, 100);
                                },
                                error: (err) => {
                                    const message = JSON.parse(err).message;
                                    super.showMessage('Atenção', message, 'error');
                                    this.toggleLoader(false);
                                },
                            });
                        } else {
                            this.message();
                            this.router.navigate(['/news']);
                        }
                    },
                    error: (err) => {
                        this.toggleLoader(false);
                    },
                }
            );
    }


    message(): void {
        this.showMessage('Sucesso', 'Notícia atualizada com sucesso!', 'success');
        this.toggleLoader(false);
    }
}
