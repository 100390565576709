<section class="single-page" >
    <!--<div class="btn-hero" [routerLink]="'/policies-procedures'">
        <svg-icon src="assets/svg/config.svg"></svg-icon>
        <span>Gestão</span>
    </div>
    <div class="btn-hero cursor-pointer" [routerLink]="'/user'">
        <svg-icon src="assets/svg/user-blue.svg"></svg-icon>
        <span>Usuários</span>
    </div>-->
    <div class="hero">
        <img src="assets/images/background.png" alt="Clic" />
    </div>

    <div class="list-featured">
        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/dashboard'">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>

        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/banner'">
                <svg-icon src="assets/svg/sidebar/content.svg"></svg-icon>
                <span>Gestão de Conteúdo</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/user'">
                <svg-icon src="assets/svg/sidebar/users.svg"></svg-icon>
                <span>Usuários</span>
            </a>
        </div>
        <!--<div class="item">
            <a href="javascript:void('');" [routerLink]="'/visita'">
                <svg-icon src="assets/svg/sidebar/flag.svg"></svg-icon>
                <span>Visita Técnica</span>
            </a>
        </div>-->
        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/contact'">
                <svg-icon src="assets/svg/sidebar/contact-us.svg"></svg-icon>
                <span>Fale Conosco</span>
            </a>
        </div>
        <!--<div class="item">
            <a href="javascript:void('');" [routerLink]="'/admin'">
                <svg-icon src="assets/svg/sidebar/user.svg"></svg-icon>
                <span>Usuários Admin</span>
            </a>
        </div>-->
        <!--<div class="item d-none">
            <a href="javascript:void('');" [routerLink]="'/report'">
                <svg-icon src="assets/svg/sidebar/user.svg"></svg-icon>
                <span>Relatórios</span>
            </a>
        </div>-->
    </div>
    <!--<div class="list-featured justify-content-center mt-3">
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/board.svg"></svg-icon>
                <span>Prancheta Eletrônica</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/news.svg"></svg-icon>
                <span>Politicas e Procedimentos</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>
    </div>-->

</section>

<section class="home" *ngIf="false">
    <div class="home_header">
        <h2 class="page_title">CLIC | <span>Dashboard</span></h2>
        <div class="home_divider">&nbsp;</div>
    </div>
</section>


