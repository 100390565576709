<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Downloads<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->
            <!-- <a [routerLink]="'/downloads-detail'"  href="javascript:void('');" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Cadastrar Download
            </a> -->
            <a href="javascript:void('excel')"  class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
            <a href="javascript:void('');" class="btn btn-sm btn-secondary btn-text-white" [routerLink]="'/downloads-detail/new'" >
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    Adicionar
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.keyword"/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>Buscar</span>
                </label>
            </div>
            <!--<div class="input-material">
                <input id="period" class="form-control js-daterangepicker" type="text" required/>
                <label for="period">Período</label>
            </div>-->
            <a class="btn btn-primary btn-lg text-white" href="javascript:void('search')" (click)="modelFilter.page = 1; searchDownload()">Buscar</a>

        </div>

        <div class="w-100 d-block py-5" *ngIf="modelDownloadResult.downloads.length === 0">
            <p class="text-center py-5">Nenhum item cadastrado</p>
        </div>

        <!--GRID-->
        <div class="table-responsive" *ngIf="modelDownloadResult.downloads.length > 0">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Nome do Arquivo</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Arquivo</th>
                    <th scope="col">Perfil de Acesso</th>
                    <th scope="col">Data de Criação</th>
                    <th scope="col">QTD de Downloads</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="false">
                    <td colspan="7">
                        <p class="text-center py-5">Nenhum registro encontrado</p>
                    </td>
                </tr>
                <tr *ngFor="let item of modelDownloadResult.downloads |
                paginate: {itemsPerPage: modelDownloadResult.pageSize,
                currentPage: modelDownloadResult.currentPage,
                totalItems: modelDownloadResult.totalCount }">
                    <td>{{item.name}}</td>
                    <td>{{item.category?.name}}</td>
                    <td>{{item.path}}</td>
                    <td>{{item.profile}}</td>
                    <td>{{item.createdAt | date:"dd/MM/yyyy"}}</td>
                    <td>
                    {{item.downloadAccess.length}}
                    </td>
                    <td>
                        <a  [routerLink]="['/downloads-detail/' + item.id]">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('remove')" (click)="handlerRemoveItem(item.id)">
                            <svg-icon src="assets/svg/close-rounded.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>

