<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Cases<!-- | <span>Gestão</span>--></h1>
            <!-- <a [routerLink]="'/news-detail'" href="javascript:void('');" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Cadastrar Notícias
            </a> -->
            <a href="javascript:void('');" class="btn btn-sm btn-info btn-text-white" (click)="getExcel()">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Exportar Excel
            </a>
            <a [routerLink]="'/cases-detail/new'"  href="javascript:void('');" class="btn btn-sm btn-secondary btn-text-white">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                Adicionar
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.keyword"/>
                <label for="name">

                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>Buscar</span>
                </label>
            </div>
            <div class="input-material">
                <input id="period" class="form-control js-daterangepicker" type="text" required/>
                <label for="period">Período</label>
            </div>
            <a class="btn btn-primary btn-lg text-white" (click)="modelFilter.page = 1; handlerSearchNews()">Buscar</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Imagem</th>
                    <th scope="col">Título</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Destaque</th>
                    <th scope="col">Agendada</th>
                    <th scope="col">Data Agendada</th>
                    <th scope="col">Data de Criação</th>
                    <th scope="col">QTD de Acesso</th>
                    <th scope="col" class="w-10 text-right"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="false">
                    <td colspan="7">
                        <p class="text-center py-5">Nenhum registro encontrado</p>
                    </td>
                </tr>
                <tr *ngFor="let item of modelNewsResult.news |
                paginate: {itemsPerPage: modelNewsResult.pageSize,
                currentPage: modelNewsResult.currentPage,
                totalItems: modelNewsResult.totalCount }">
                    <td><img class="imgThumb" [src]="getImageUrl(item.pathImage)" alt=""></td>
                    <td>{{item.title}}</td>
                    <td><div class="html-block" [innerHTML]="item.text"></div></td>
                    <td>{{item.highlight ? 'Sim' : 'Não'}}</td>
                    <td>{{item.scheduled  ? 'Sim' : 'Não'}}</td>
                    <td>{{item.start | date:"dd/MM/yyy"}} - {{item.end | date:"dd/MM/yyyy"}}</td>
                    <td>{{item.createdAt | date:"dd/MM/yyyy"}}</td>
                    <td>{{item.newsAccess.length}}</td>
                    <td class="text-right">
                        <a  [routerLink]="['/articles-detail/' + item.id]">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('')" (click)="handlerRemoveItem(item.id)">
                            <svg-icon src="assets/svg/close-rounded.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

    </div>
</div>

